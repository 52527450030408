exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-campaign-tsx": () => import("./../../../src/pages/campaign.tsx" /* webpackChunkName: "component---src-pages-campaign-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-success-stories-tsx": () => import("./../../../src/pages/success-stories.tsx" /* webpackChunkName: "component---src-pages-success-stories-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-career-inside-template-tsx": () => import("./../../../src/templates/careerInsideTemplate.tsx" /* webpackChunkName: "component---src-templates-career-inside-template-tsx" */),
  "component---src-templates-detail-blog-tsx": () => import("./../../../src/templates/detailBlog.tsx" /* webpackChunkName: "component---src-templates-detail-blog-tsx" */),
  "component---src-templates-detail-success-story-tsx": () => import("./../../../src/templates/detailSuccessStory.tsx" /* webpackChunkName: "component---src-templates-detail-success-story-tsx" */),
  "component---src-templates-service-detail-template-tsx": () => import("./../../../src/templates/serviceDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-service-detail-template-tsx" */),
  "component---src-templates-services-template-tsx": () => import("./../../../src/templates/servicesTemplate.tsx" /* webpackChunkName: "component---src-templates-services-template-tsx" */)
}

